import React from 'react';
import "../Styles/Smoke.css"
function Smoke(){
    return(
      <>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>
            <div class="Smoke"/>

      </>
    );
}
export default Smoke;

