import React from 'react';
import "../Styles/Leaf.css"
function Leaf(){
    return(
      <>
      <div className="hide">
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
            <div class="leaf"/>
      </div>
      </>
    );
}
export default Leaf;

